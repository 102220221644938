




























































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Filter } from "@/utils/Filter";
import ClinicDropdown from "@/components/dropdowns/ClinicDropdown.vue";
import DiagnosisDropdown from "@/components/dropdowns/DiagnosisDropdown.vue";

@Component({
  components: {
    ClinicDropdown,
    DiagnosisDropdown
  }
})
export default class UserSearchFilters extends Vue {
  @Prop({type: Object, default: () => ({})}) locationFilter!: Filter
  @Prop({type: Object, default: () => ({})}) clinicFilter!: Filter
  @Prop({type: Object, default: () => ({})}) minAgeFilter!: Filter
  @Prop({type: Object, default: () => ({})}) maxAgeFilter!: Filter
  @Prop({type: Object, default: () => ({})}) genderFilter!: Filter
  @Prop({type: Object, default: () => ({})}) diagnosisFilter!: Filter
}
