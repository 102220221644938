import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yc_chat_list"},[(_vm.showFriendSearch)?_c('div',{staticClass:"friendlist_window"},[_c('Friendlist',{on:{"closeFriendlist":function($event){_vm.showFriendSearch = false}}})],1):_c('div',{staticClass:"chat_window"},[_c('chat-window',{attrs:{"current-user-id":_vm.currentUserId,"rooms":_vm.chats,"loading-rooms":_vm.loadingRooms,"messages":_vm.messages,"menu-actions":_vm.menuActions,"room-actions":_vm.menuActions,"text-messages":_vm.textMessages,"show-audio":false,"show-files":false,"responsive-breakpoint":_vm.responsiveBreakpoint,"messages-loaded":_vm.messagesLoaded},on:{"menu-action-handler":_vm.menuActionHandler,"room-action-handler":_vm.menuActionHandler,"fetch-messages":_vm.fetchMessages,"send-message":_vm.sendMessage,"add-room":function($event){return _vm.openFriendSearch()}}})],1),_c(VDialog,{attrs:{"scrollable":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly},on:{"click:outside":function($event){_vm.showUserDetaillOverlay = false}},model:{value:(_vm.showUserDetaillOverlay),callback:function ($$v) {_vm.showUserDetaillOverlay=$$v},expression:"showUserDetaillOverlay"}},[_c(VCard,[_c(VCardTitle,{staticClass:"yc_top_bar noPadding"},[_c('div',{staticClass:"dialogTitle"},[_vm._v(_vm._s(_vm.$t("foreign_profile")))]),_c(VBtn,{attrs:{"id":"eventform-closebutton","fab":"","small":""},on:{"click":function($event){_vm.showUserDetaillOverlay = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('ProfileDetails',{staticClass:"yc_profile_details",attrs:{"user":_vm.partner,"ownprofile":false,"is-overlay":true}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }