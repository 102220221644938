




































































import { Component, Prop, Vue } from "vue-property-decorator"
import UserRepository from "@/api/UserRepository"
import User from "@/models/User"
import Diagnosis from "@/models/Diagnosis"
import Clinic from "@/models/Clinic"
import UserSearchFilter from "@/models/UserSearchFilter"
import { Filter } from "@/utils/Filter"
import UserSearchResults from "@/components/social/UserSearchResults.vue"
import UserSearchFilters from "@/components/social/UserSearchFilters.vue"

import DiagnosisRepository from "@/api/DiagnosisRepository"
import ClinicRepository from "@/api/ClinicRepository"
import TrackingRepository from "@/api/TrackingRepository"

@Component({
  components: {
    UserSearchResults,
    UserSearchFilters
  }
})
export default class UserSearch extends Vue {
  @Prop({ type: Object, required: true }) user!: User
  //filtering
  filtermode: boolean = false
  minAge: number = 10
  maxAge: number = 30
  locationFilter: Filter = {} as Filter
  clinicFilter: Filter = {} as Filter
  genderFilter: Filter = {} as Filter
  minAgeFilter: Filter = {} as Filter
  maxAgeFilter: Filter = {} as Filter
  diagnosisFilter: Filter = {} as Filter
  filters: Filter[] = []
  //searching
  searchOffset: number = 0
  standardOffset: number = 5
  searchLimit: number = 5
  searchterm: string = ""
  moreResultsExpected: boolean = true
  noSearchResults: boolean = false
  userSearchResults: User[] = []

  created(): void {
    this.createLocationFilter()
    this.createClinicFilter()
    this.createGenderFilter()
    this.createMinAgeFilter()
    this.createMaxAgeFilter()
    this.createDiagnosisFilter()
    this.searchUser(true)
  }
  createLocationFilter(): void {
    this.locationFilter = new Filter("filter_location", "cards", ["clinic", "home"])
    this.filters.push(this.locationFilter)
  }
  async createClinicFilter(): Promise<void> {
    //TODO: Exchange with endpoint
    const clinics = await this.loadClinics()
    this.clinicFilter = new Filter("filter_clinic", "cards", clinics ? clinics : [], {})
    this.filters.push(this.clinicFilter)
  }
  createGenderFilter(): void {
    this.genderFilter = new Filter("filter_gender", "cards", ["M", "F", "D"])
    this.filters.push(this.genderFilter)
  }
  createMinAgeFilter(): void {
    const availabeAges: Array<number> = Array.from(
      { length: this.maxAge - this.minAge },
      (v, k) => k + this.minAge
    )
    this.minAgeFilter = new Filter("filter_min_age", "dropdown", availabeAges)
    this.filters.push(this.minAgeFilter)
  }
  createMaxAgeFilter(): void {
    const availabeAges: Array<number> = Array.from(
      { length: this.maxAge - this.minAge },
      (v, k) => k + this.minAge
    )
    this.maxAgeFilter = new Filter("filter_max_age", "dropdown", availabeAges)
    this.filters.push(this.maxAgeFilter)
  }
  async createDiagnosisFilter(): Promise<void> {
    const diagnoses = await this.loadDiagnoses()
    this.diagnosisFilter = new Filter(
      "filter_diagnosis",
      "dropdown",
      diagnoses ? diagnoses : [],
      {}
    )
    this.filters.push(this.diagnosisFilter)
  }

  setFilterValue(arg: any): void {
    const filter = this.filters.filter((filter) => filter.name == arg.filterName)[0]
    filter.setFilterValue(arg.value)
  }

  resetSingleFilter(filterName: string): void {
    const filter = this.filters.filter((filter) => filter.name == filterName)[0]
    filter.resetFilter()
  }
  resetAllFilters(): void {
    this.filters.map((filter) => filter.resetFilter())
  }
  getChipLabel(filter: Filter): string | undefined {
    switch (filter.name) {
      case "filter_location":
        return this.$t(filter.name) + ": " + this.$t(filter.value)
      case "filter_clinic":
        return this.$t(filter.name) + ": " + filter.value.address + " - " + filter.value.name
      case "filter_gender":
        return this.$t(filter.name) + ": " + this.$t(filter.value)
      case "filter_min_age":
        return this.$t(filter.name) + ": " + this.$t(filter.value.toString())
      case "filter_max_age":
        return this.$t(filter.name) + ": " + this.$t(filter.value.toString())
      case "filter_diagnosis":
        return this.$t(filter.name) + ": " + this.$t("diagnoses." + filter.value.name)
    }
  }
  getNumActiveFilters(): number {
    return this.filters.filter((filter) => filter.isActive === true).length
  }
  getClinicParam(): string | number {
    if (this.locationFilter.value === "clinic") return 1
    else if (this.locationFilter.value === "home") return 2
    else return ""
  }
  buildFilterObject(): UserSearchFilter {
    return {
      search: this.searchterm,
      age_min: this.minAgeFilter.value,
      age_max: this.maxAgeFilter.value,
      at_clinic: this.getClinicParam(),
      sex: this.genderFilter.value,
      treated_at: this.clinicFilter.value ? this.clinicFilter.value.name : "",
      diagnosis: this.diagnosisFilter.value ? this.diagnosisFilter.value.id : "",
      limit: this.searchLimit,
      offset: this.searchOffset
    }
  }
  async loadDiagnoses(): Promise<Diagnosis[] | void> {
    try {
      return await DiagnosisRepository.get()
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.diagnoses"))
      /*eslint-disable no-console*/
      console.error("Error fetching diagnoses: " + error)
    }
  }

  async loadClinics(): Promise<Clinic[] | void> {
    try {
      return await ClinicRepository.get()
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.diagnoses"))
      /*eslint-disable no-console*/
      console.error("Error fetching diagnoses: " + error)
    }
  }
  async logSearch(searchObj: UserSearchFilter): Promise<void> {
    //Suche User mit Aufenthalt X, Klinik X, Geschlecht X, Min. Alter X, Max. Alter X, Diagnose X, Suchbegriff X, X Ergebnisse - eigner Aufenthalt Y, Klinik Y, Geschlecht Y, Min. Alter Y, Max. Alter Y, Diagnose Y

    let at_clinic = ""
    if (searchObj.at_clinic != "")
      at_clinic = searchObj.at_clinic == 1 ? ", Aufenthalt 'Klinik'" : ", Aufenthalt 'Zuhause'"

    const clinic =
      searchObj.treated_at != ""
        ? ", Klinik '" + this.clinicFilter.value.name + " " + this.clinicFilter.value.address + "'"
        : ""
    const gender = searchObj.sex != "" ? ", Geschlecht '" + searchObj.sex + "'" : ""
    const age_min = searchObj.age_min != "" ? ", Min. Alter '" + searchObj.age_min + "'" : ""
    const age_max = searchObj.age_max != "" ? ", Max. Alter '" + searchObj.age_max + "'" : ""
    const diagnosis =
      searchObj.diagnosis != "" ? ", Diagnose '" + this.diagnosisFilter.value.name + "'" : ""
    const search = searchObj.search != "" ? ", Suchbegriff '" + searchObj.search + "'" : ""

    const user = this.$store.getters.getUser
    const own_at_clinic = user.at_clinic ? "Klinik" : "Zuhause"

    const groupID = "CHAT:"
    await TrackingRepository.createLog(
      groupID +
        "Suche nach User mit " +
        at_clinic +
        clinic +
        gender +
        age_min +
        age_max +
        diagnosis +
        search +
        " - eigener Aufenthalt '" +
        own_at_clinic +
        "', Klinik '" +
        user.treated_at.name +
        " " +
        user.treated_at.address +
        "', Geschlecht '" +
        user.sex +
        "', Alter '" +
        user.age +
        "', Diagnose '" +
        user.diagnosis.name +
        "'"
    )
  }
  async searchUser(newSearch: boolean): Promise<void> {
    // in case of new search: clear all necessary fields
    if (newSearch) {
      this.moreResultsExpected = true
      this.userSearchResults = []
    }
    // set offset and limit according to new search / extended search
    this.changeOffsetAndLimit(newSearch)

    // build parameter object depending on chosen filters
    const searchObject = this.buildFilterObject()

    try {
      const filtered_users = await UserRepository.searchUser(searchObject)
      this.logSearch(searchObject)

      // check if response contains any entries - and if result list is empty (new search)
      if (filtered_users.count == 0 && this.userSearchResults.length == 0) {
        this.noSearchResults = true
        this.moreResultsExpected = false
        return
      }

      this.noSearchResults = false

      // check if more results are to be expected (count > current search offset)
      if (filtered_users.count <= this.searchOffset + this.standardOffset)
        this.moreResultsExpected = false

      // add all results to array
      filtered_users.results.forEach((user) => this.userSearchResults.push(user))
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.users"))
      /*eslint-disable no-console */
      console.error("Error while requesting users: " + error)
    }
  }
  changeOffsetAndLimit(newSearch: boolean): void {
    if (newSearch) {
      this.searchOffset = 0
      this.searchLimit = this.standardOffset
    } else {
      this.searchOffset += this.standardOffset
    }
  }
  clearSearchResults(): void {
    this.userSearchResults = []
    //this.filters = [];
  }
}
