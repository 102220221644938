



















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import User from "@/models/User";
import ProfileDetails from "@/components/social/ProfileDetails.vue";
import UserRepository from "@/api/UserRepository";
//import ContentRepository from "@/api/ContentRepository";

@Component({
  components: {
    ProfileDetails
  }
})
export default class UserSearchResults extends Vue {
  @Prop({type: Array, default: () => []}) users!: User[]
  @Prop({type: Object}) currUser: User | undefined
  showUserId: number = -1
  user: User = {} as User
  userLoaded: boolean = false
  //avatar: string | null = null

  async fetchUser(user_id: number): Promise<void> {
    try {
      this.user = await UserRepository.getUser(user_id, true);
      //this.avatar = await this.fetchAvatar(this.user.avatar);
      //this.avatar = this.user.avatar!
      this.userLoaded = true;
      this.showUserId = this.user.id;
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.user"));
      /*eslint-disable no-console */
      console.error("Error while fetching user: " + error);
    }
  }
  /*
  async fetchAvatar(src: string | null): Promise<string|null> {
      if(!src) {
          return null;
      }
      const encoded = await ContentRepository.getAuthenticated(src);
      return encoded.toDataURI();
  },
  */
  checkIfFriends(foreignUser: User): boolean {
    return this.$store.getters.relations.some(
      (relation: any) =>
        relation.to_user.id == foreignUser.id ||
        relation.from_user.id == foreignUser.id
    );
  }
  checkifRequested(foreignUser: User): boolean {
    return this.$store.getters.relationRequests.some(
      (relation: any) =>
        relation.to_user.id == foreignUser.id ||
        relation.from_user.id == foreignUser.id
    );
  }
  checkifBlocked(foreignUser: User): boolean {
    return this.$store.getters.blocked.some(
      (relation: any) => relation.to_user.id == foreignUser.id
    );
  }
  get showUser(): boolean {
    return this.showUserId >= 0;
  }
}
