




























































import { Component, Watch, Vue } from "vue-property-decorator"
import Friendlist from "@/components/social/Friendlist.vue"
import ChatWindow from "vue-advanced-chat"
import "vue-advanced-chat/dist/vue-advanced-chat.css"
import UserRepository from "@/api/UserRepository"
import ProfileDetails from "@/components/social/ProfileDetails.vue"
//import OnbChatHints from "@/components/onboarding/OnbChatHints.vue";
import User from "@/models/User"
import { ICR, ICM } from "@/store/types"
import TrackingRepository from "@/api/TrackingRepository"
import { NavigationGuardNext, Route } from "vue-router"

interface ActiveRoom {
  room_id: string
  is_blocked: boolean
  partner: number
}

@Component({
  components: {
    ChatWindow,
    Friendlist,
    ProfileDetails
    // OnbChatHints
  }
})
export default class ChatList extends Vue {
  overlayDiv: HTMLElement = {} as HTMLElement
  activeRoom: ActiveRoom | {} = {}
  polling: NodeJS.Timer = {} as NodeJS.Timer
  unreadPolling: NodeJS.Timer = {} as NodeJS.Timer
  chatMessagePollingTime: number = 2000
  chatUnreadMessagePollingTime: number = 10000
  partner: User = {} as User
  showFriendSearch: boolean = false
  showUserDetaillOverlay: boolean = false
  loadingRooms: boolean = false
  messagesLoaded: boolean = true
  showChatHints: boolean = false
  currentUserId: number = 0
  showUserId: number = -1
  responsiveBreakpoint: number = 768
  // roomId: '',
  menuActions: { name: string; title: string }[] = [
    {
      name: "showProfile",
      title: "Profil anzeigen"
    }
    /*
    {
        name: "deleteRoom",
        title: "Unterhaltung löschen",
    },
    */
  ]

  // used to replace default i18n texts
  textMessages: any = {
    ROOMS_EMPTY: this.$t("chatlist.rooms_empty"),
    ROOM_EMPTY: this.$t("chatlist.room_empty"),
    NEW_MESSAGES: this.$t("chatlist.new_messages"),
    MESSAGE_DELETED: this.$t("chatlist.message_deleted"),
    MESSAGES_EMPTY: this.$t("chatlist.messages_empty"),
    CONVERSATION_STARTED: this.$t("chatlist.conversation_started"),
    TYPE_MESSAGE: this.$t("chatlist.type_message"),
    SEARCH: this.$t("chatlist.search"),
    IS_ONLINE: this.$t("chatlist.is_online"),
    LAST_SEEN: this.$t("chatlist.last_seen"),
    IS_TYPING: this.$t("chatlist.is_typing")
  }

  async created(): Promise<void> {
    //await this.$store.dispatch("chat/leave");

    if (this.$store.getters["chat/myself"]._id === undefined)
      await this.$store.dispatch("integrateUserDataFromBackend")

    // load own user id
    this.currentUserId = this.$store.getters["chat/myself"]._id

    // wait for the chat to be loaded, to execute following statements
    await Promise.all([this.$store.dispatch("chat/fetch"), this.$store.dispatch("fetchRelations")])
    this.loadingRooms = false
    this.messagesLoaded = true

    // set first chat as active chat, if there are chats
    if (this.chats.length > 0) {
      this.$store.dispatch("chat/activate", { room: this.chats.at(0) })
    }
  }

  @Watch("activeRoom")
  async onActiveRoomChange(): Promise<void> {
    const groupID = "CHAT:"
    // @ts-ignore
    const chat_parnter = this.activeRoom.partner._id
    await TrackingRepository.createLog(groupID + "Klick auf Chat mit User " + chat_parnter)

    //@ts-ignore
    if (this.activeRoom.is_blocked) {
      //document.getElementsByClassName("vac-col-messages")[0].classList.add("bocked_chat_overlay");
      //document.getElementsByClassName("vac-messages-container")[0].appendChild(this.overlayDiv)
      let parentDiv = document.getElementById("messages-list")
      let child = document.getElementsByClassName("vac-messages-container")[0]

      parentDiv!.insertBefore(this.overlayDiv, child)
    } else {
      document.getElementById("bocked_chat_overlay")?.remove()
    }
    if (this.chats.find((x) => x.roomId === (this.activeRoom as ActiveRoom).room_id)?.unreadCount)
      this.$store.commit("chat/updateChat", {
        roomId: (this.activeRoom as ActiveRoom).room_id,
        unreadCount: 0
      })
  }

  get chats(): ICR[] {
    return this.$store.getters["chat/getChatRooms"]
  }
  get messages(): ICM[] {
    return this.$store.getters["chat/getMessages"]
  }
  /*
  get chatHintStatus(): boolean {
    return this.$store.getters.getOnboardingSetting("chat_hints");
  }
  */

  beforeMount(): void {
    // set polling
    this.polling = setInterval(() => {
      this.$store.dispatch("chat/fetch")
      this.$store.dispatch("chat/fetchMessages")
    }, this.chatMessagePollingTime)

    this.unreadPolling = setInterval(() => {
      this.$store.dispatch("chat/fetchUnread")
    }, this.chatUnreadMessagePollingTime)
  }
  beforeDestroy(): void {
    clearInterval(this.polling)
    clearInterval(this.unreadPolling)
    this.$store.dispatch("chat/leave")
    document.getElementsByTagName("html")[0].classList.remove("noScrollY")
  }
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): any {
    // unset polling
    clearInterval(this.polling)
    clearInterval(this.unreadPolling)
    this.$store.dispatch("chat/leave")
    next()
  }
  mounted(): void {
    //this.showChatHints = this.$store.getters.getOnboardingSetting("chat_hints");

    document.getElementsByTagName("html")[0].classList.add("noScrollY")
    document.getElementsByClassName("vac-messages-container")[0].classList.add("scrollY")

    let overlayMessageDiv = document.createElement("div")
    overlayMessageDiv.classList.add("blocked_text")
    overlayMessageDiv.innerHTML = "" + this.$t("error_messages.blocked_chat")

    this.overlayDiv = document.createElement("div")
    this.overlayDiv.id = "bocked_chat_overlay"
    this.overlayDiv.classList.add("bocked_chat_overlay")
    this.overlayDiv.appendChild(overlayMessageDiv)
  }
  async openFriendSearch(): Promise<void> {
    const groupID = "CHAT:"
    await TrackingRepository.createLog(groupID + "Öffnen der Freundessuche")
    this.showFriendSearch = true
  }
  timestampToHoursMinutes(timestamp: any): string | null {
    const date = new Date(timestamp)
    const minutes = date.getMinutes()
    return `${date.getHours()}:${minutes > 9 ? minutes : "0" + minutes}`
  }
  async menuActionHandler(actionObj: any): Promise<void> {
    switch (actionObj.action.name) {
      case "showProfile":
        try {
          let userId =
            this.chats.find((chat: ICR) => chat.roomId == actionObj.roomId)?.users[1]._id || -1

          if (userId >= 0) {
            //this.partner = await UserRepository.getUser(this.$store.getters["chat/getPartnerId"]);
            this.partner = await UserRepository.getUser(userId, true)
            //this.userLoaded = true;
            this.showUserId = this.partner.id

            this.showUserDetaillOverlay = true
          }
        } catch (error) {
          this.$store.dispatch("alert/error", this.$t("error_messages.user"))
          /* eslint-disable no-console */
          console.error("Error occured while fetching user: " + error)
        }
        break
      /*
      case "deleteRoom":
        // call a method to delete the room
        break;
      */
    }
  }
  async fetchMessages(roomObj: any /*, options: any*/): Promise<void> {
    // this.messages = []
    //this.messagesLoaded = false;

    // store active room locally, to check if 'block overlay' is needed
    const chat_partner_id = roomObj.room.users.find((user: { username: any; _id: any }) => {
      if (user.username == roomObj.room.roomName) {
        return user._id
      }
    })
    this.activeRoom = {
      room_id: roomObj.room.roomId,
      is_blocked: roomObj.room.is_blocked,
      partner: chat_partner_id
    }

    // set active room and load messages for roomId
    await Promise.all([
      this.$store.dispatch("chat/activate", roomObj),
      this.$store.dispatch("chat/fetchMessages")
      // this.$store.dispatch("fetchRelations"),
    ])

    // this.messages = this.$store.getters["chat/getMessages"];

    this.messagesLoaded = true
  }
  async sendMessage(messageObj: any): Promise<void> {
    await this.$store.dispatch("chat/sendMessage", messageObj)
  }

  get chatNotificationsCount(): number {
    return this.$store.getters.getChatNotificationsCount
  }

  @Watch("chatNotificationsCount")
  onNewChatNotifications(count: number) {
    if (count > 0) this.$store.dispatch("markChatNotificationsAsSeen")
  }
}
