import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"justify-center yc_friendlist",attrs:{"fluid":""}},[_c(VToolbar,{staticClass:"pa-0 friends_toolbar",attrs:{"dark":""}},[_c(VRow,{staticClass:"toolbar",attrs:{"no-gutters":""}},[_c('div',{staticClass:"toolbar_left_icons"},[_c(VBtn,{style:({
            backgroundColor: _vm.searchActive ? 'white !important' : '',
          }),attrs:{"icon":"","rounded":""},on:{"click":function($event){return _vm.chooseComponent('search')}}},[_c(VIcon,{attrs:{"data-cy":"addfriendsbtn"}},[_vm._v("mdi-account-plus")])],1),_c(VBtn,{style:({
            backgroundColor: _vm.requestsActive ? 'white !important' : '',
          }),attrs:{"icon":"","rounded":""},on:{"click":function($event){return _vm.chooseComponent('request')}}},[_c(VIcon,{attrs:{"data-cy":"requestsection"}},[_vm._v("mdi-account-question")])],1)],1),_c('div',{staticClass:"toolbar_title"},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("friends")))])],1),_c('div',{staticClass:"toolbar_right_icons"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('closeFriendlist')}}},[_c(VIcon,{attrs:{"data-cy":"closefriendlistbtn"}},[_vm._v("mdi-close")])],1)],1)])],1),_c('div',{staticClass:"scroll-container"},[_c('UserSearch',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchActive),expression:"searchActive"}],staticClass:"yc_user_search",attrs:{"user":_vm.getSelf()}}),_c('FriendRequestList',{directives:[{name:"show",rawName:"v-show",value:(_vm.requestsActive),expression:"requestsActive"}]})],1),_c(VDialog,{attrs:{"fullscreen":true,"scrollable":""},on:{"click:outside":function($event){_vm.showUserId = -1}},model:{value:(_vm.showUser),callback:function ($$v) {_vm.showUser=$$v},expression:"showUser"}},[_c(VCard,[_c(VCardTitle,{staticClass:"yc_top_bar noPadding"},[_c('div',{staticClass:"dialogTitle"},[_vm._v(_vm._s(_vm.$t("foreign_profile")))]),_c(VBtn,{attrs:{"id":"eventform-closebutton","fab":"","small":""},on:{"click":function($event){_vm.showUserId = -1}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('ProfileDetails',{staticClass:"yc_profile_details",attrs:{"user":_vm.friend,"ownprofile":false,"is-overlay":true}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }