














































































import { Component, Vue } from 'vue-property-decorator'
import FriendRequestList from "@/components/social/FriendRequestList.vue";
import UserSearch from "@/components/social/UserSearch.vue";
import User from "@/models/User";
import ProfileDetails from "@/components/social/ProfileDetails.vue";
import UserRepository from "@/api/UserRepository";
import TrackingRepository from "@/api/TrackingRepository";
import Relation from "@/models/Relation";

@Component({
  components: {
    FriendRequestList,
    UserSearch,
    ProfileDetails
  }
})
export default class Friendlist extends Vue {
  searchActive: boolean = true
  //listActive: boolean = true
  requestsActive: boolean = false
  showUserId: number = -1
  friend: User = {} as User
  userLoaded: boolean = false
  isnew: boolean = true

  /*
  get friends(): Relation[] {
    return this.$store.getters.relations
  }
  */
  get showUser(): boolean {
    return this.showUserId >= 0
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch("integrateUserDataFromBackend")
  }
  async fetchUser(user_id: number): Promise<void> {
    try {
      this.friend = await UserRepository.getUser(user_id, true);
      this.userLoaded = true;
      this.showUserId = this.friend.id;
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.user"));
      /* eslint-disable no-console */
      console.error("Error occured while fetching user: " + error);
    }
  }
  getSelf(): User {
    return this.$store.getters.getUser;
  }
  async chooseComponent(component: string): Promise<void> {
    const groupID = 'CHAT:'
    switch (component) {
      case "request":
        this.requestsActive = true;
        //this.listActive = false;
        this.searchActive = false;          
        await TrackingRepository.createLog(groupID+"Klick auf Freundschaftsbeziehungen")
        break;
      /*
      case "list":
        this.listActive = true;
        this.requestsActive = false;
        this.searchActive = false;
        break;
      */
      case "search":
        this.searchActive = true;
        this.requestsActive = false;
        //this.listActive = false;
        await TrackingRepository.createLog(groupID+"Klick auf Freundessuche")
        break;
    }
  }
}
