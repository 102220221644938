interface IFilter {
  name: string
  isActive: boolean
  value: string | number | any
  options: Array<string | number | Object>
  defaultValue: string | number | any
  type: string
}

export class Filter implements IFilter {
  name: string
  isActive: boolean
  value: string | number | any
  options: Array<string | number | Object>
  defaultValue: string | number | any
  type: string

  constructor(
    name: string,
    type: "calendar" | "cards" | "dropdown" | "icons",
    options: Array<string | number | any>,
    defaultValue?: string | number | any
  ) {
    this.name = name
    this.isActive =
      defaultValue instanceof Object ? Object.keys(defaultValue).length != 0 : !!defaultValue
    this.defaultValue = defaultValue ? defaultValue : ""
    this.value = defaultValue ? defaultValue : ""
    this.options = options
    this.type = type
  }

  public setFilterValue(value: string | number | any): void {
    const isValidValue =
      typeof value === "object"
        ? this.options.some((option) => JSON.stringify(option) === JSON.stringify(value))
        : this.options.includes(value)
    if (isValidValue) {
      this.value = value
      this.isActive = true
    } else {
      console.warn("Trying to set value which was not defined in options")
    }
  }

  public resetFilter(): void {
    this.value = this.defaultValue
    this.isActive = false
  }
}
