
































































































import { Vue, Component } from "vue-property-decorator"
import UserRepository from "@/api/UserRepository"
import RelationsRepository from "@/api/RelationsRepository"
import User from "@/models/User"
import ProfileDetails from "@/components/social/ProfileDetails.vue"
import TrackingRepository from "@/api/TrackingRepository"

@Component({
  components: {
    ProfileDetails
  }
})
export default class FriendRequestList extends Vue {
  showUserId: number = -1
  friend: User = {} as User
  userLoaded: boolean = false

  get items() {
    return [
      {
        icon: "mdi-account-arrow-left",
        title: "received_requests",
        userToDisplay: "from_user",
        requests: [...this.$store.getters.receivedRequests]
      },
      {
        icon: "mdi-account-arrow-right",
        title: "send_requests",
        userToDisplay: "to_user",
        requests: [...this.$store.getters.sendRequests]
      },
      {
        icon: "mdi-account-off",
        title: "blocked_users",
        userToDisplay: "to_user",
        requests: [...this.$store.getters.blockedUsers]
      }
    ]
  }
  get showUser(): boolean {
    return this.showUserId >= 0
  }

  async acceptRequest(relationsId: Number): Promise<void> {
    try {
      const relation = await RelationsRepository.confirmRelationRequest(relationsId)
      this.$store.commit("updateRelation", relation)

      // create chat
      this.$store.dispatch("chat/create", relation.from_user.id)

      const groupID = "CHAT:"
      await TrackingRepository.createLog(
        groupID +
          "Freundschaftsanfrage von User " +
          relation.from_user.id +
          " annehmen über Freundschaftsbeziehungen"
      )
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.change_relation"))
      /*eslint-disable no-console */
      console.error("Error while updating relation: " + error)
    }
  }
  async deleteRequest(relationsId: Number, menu_title: string, user_id: number): Promise<void> {
    const groupID = "CHAT:"
    if (menu_title === "blocked_users")
      await TrackingRepository.createLog(
        groupID + "Blockierung von User " + user_id + " aufheben über Freundschaftsbeziehungen"
      )
    if (menu_title === "send_requests")
      await TrackingRepository.createLog(
        groupID +
          "Freundschaftsanfrage an User " +
          user_id +
          " löschen über Freundschaftsbeziehungen"
      )
    if (menu_title === "received_requests")
      await TrackingRepository.createLog(
        groupID +
          "Freundschaftsanfrage von User " +
          user_id +
          " ablehnen über Freundschaftsbeziehungen"
      )

    this.$store.dispatch("deleteRequest", relationsId)
  }
  async fetchUser(user_id: number): Promise<void> {
    try {
      this.friend = await UserRepository.getUser(user_id, true)
      this.userLoaded = true
      this.showUserId = this.friend.id
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.user"))
      /* eslint-disable no-console */
      console.error("Error while fetching user: " + error)
    }
  }
}
